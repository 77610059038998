<template>
  <div>
    <h5>{{ $t('dashboard.update_frequency') }}: <strong>{{ time }}s</strong></h5>
    <hr>

    <template v-if="chartData.length === 0">
      <p>
        No data found
      </p>
    </template>
    <template v-else>
      <line-chart
        id="dash-chart-1"
        :data="chartData"
      />
    </template>
  </div>
</template>

<script>

import { userDashboardService } from '@/services/user/dashboard'

export default {
  data() {
    return {
      userDashboardService: userDashboardService(this.$http),
      time: 10,
      chartData: [],
    }
  },
  mounted() {
    this.loadChartData()
  },
  methods: {
    async loadChartData() {
      const chartData = await this.userDashboardService.getChartData()

      if (chartData.data[0].length > 0) {
        this.chartData = this.mountChartData(chartData.data)
      }
    },
    mountChartData(data) {
      const finalData = []
      data[0].map(item => {
        finalData.push([
          item.date,
          item.percentage,
        ])

        return item
      })
      return finalData
    },
  },
}
</script>
