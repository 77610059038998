const userDashboardService = http => {
  const endpointAdmin = `${process.env.VUE_APP_API_BASE_URL}bridge/chart-data`

  const getChartData = () => http.get(endpointAdmin).then(data => data.data)

  return {
    getChartData,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  userDashboardService,
}
